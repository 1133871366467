import { Outlet } from '@remix-run/react';
import { type ReactNode } from 'react';

import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../components/ConfirmCancelModalContext';
import { LayoutAnchorProvider } from '../components/LayoutAnchors/LayoutAnchors';
import { ProvidersList } from '../components/ProvidersList';
import { RoutedGamePackProviders } from '../components/RoutedGamePack';
import { AdminGamePackWrapper } from '../pages/Admin/GamePack';

export function Component() {
  const providers = [
    <RoutedGamePackProviders />,
    <LayoutAnchorProvider />,
    <ConfirmCancelModalProvider />,
  ];

  return (
    <ProvidersList providers={providers}>
      <Outlet />
      <ConfirmCancelModalRoot />
    </ProvidersList>
  );
}

export function CourseLayout(props: {
  contentKey?: string;
  children?: ReactNode;
}) {
  return (
    <AdminGamePackWrapper contentKey={props.contentKey}>
      {props.children}
    </AdminGamePackWrapper>
  );
}
